<template>
<div class="col-12">
    <div class="card">
        <Loader v-model="loading" />
        <Helper v-model="Helper" header="Buscar Movimiento" :headers="movementHeaders" :rows="entities" @selected="selectMovement" />
        <Helper v-model="requestHelper" header="Buscar Pedido" :headers="requestHeaders" :rows="orders" @selected="selectRequest" />
        <Helper v-model="purchaseHelper" header="Buscar Orden de Compra" :headers="purchaseHeaders" :rows="purchases" @selected="selectRequest" />
        <Helper v-model="warehouseHelper" header="Buscar Almacén" :headers="warehouseHeaders" :rows="warehouses" @selected="selectWarehouse" />
        <Helper v-model="subwarehouseHelper" header="Buscar Subalmacén" :headers="subwarehouseHeaders" :rows="subwarehouses" @selected="selectSubwarehouse" />
        <Helper v-model="itemHelper" header="Buscar Artículo" :headers="itemHeaders" :rows="articles" @selected="selectItem" />
        <Panel header="Entrada/Salida de Almacén">
            <BasicFormToolbar v-if="!view" @new="openNew" @save="save" :actions="['new','save']" />
            <div class="p-fluid formgrid grid">
            <FormInputText wrapperClass="field col-3" label="Folio" @search="(Helper.visible=true)" :search="true" v-model="entity.id" :valid="validate.validations.id"/>
            <FormCalendar wrapperClass="field col-3" label="Fecha"  v-model="entity.date" :valid="validate.validations.date"/>
            <FormDropdown @change="typeChanged" wrapperClass="field col-3" label="Tipo" :options="type" :optionLabel="'text'" :optionValue="'id'" v-model="entity.type_movement" :valid="validate.validations.type_movement"/>
            <FormDropdown wrapperClass="field col-3" label="Movimiento" :options="movement" :optionLabel="'text'" :optionValue="'text'" v-model="entity.movement" :valid="validate.validations.movement"/>
            
            <div v-if="entity.type_movement == 2 & entity.movement == 'Salida por pedido'" class="field col-3">
              <FormInputText label="Pedido" @search="(requestHelper.visible=true)" :search="true" v-model="entity.id_request"/>
            </div>

            <div v-if="entity.type_movement == 1 & entity.movement == 'Entrada por orden de compra'" class="field col-3">
              <FormInputText label="Orden de compra" @search="(purchaseHelper.visible=true)" :search="true"/>
            </div>

            <FormInputText wrapperClass="field col-3" label="Comentarios" :textArea="true" v-model="entity.comments"/>
            </div>
        </Panel><br>

        <Panel header="Items">
          <BasicFormToolbar v-if="!view" @save="saveItem" :actions="['save']" />
          <div class="p-fluid formgrid grid">
            <FormInputText wrapperClass="field col-3" label="Artículo" @search="(itemHelper.visible=true)" :search="true" v-model="item.id_item" :valid="validateItem.validations.id_item"/>
            <FormInputText wrapperClass="field col-3" label="Almacén" @search="(warehouseHelper.visible=true)" :search="true" v-model="item.id_inventory_warehouse" :valid="validateItem.validations.id_inventory_warehouse"/>
            <FormInputText wrapperClass="field col-3" label="SubAlmacén" @search="(subwarehouseHelper.visible=true)" v-model="item.id_inventory_subwarehouse" :search="true" :valid="validateItem.validations.subwarehose"/>
            <FormInputNumber wrapperClass="field col-2" label="Cantidad" :search="true" v-model="item.quantity" :valid="validateItem.validations.quantity"/>
            <FormInputNumber wrapperClass="field col-1" label="Costo" :search="true" v-model="item.unit_price" mode="currency" :valid="validateItem.validations.unit_price" />
          </div>
        </Panel><br>

        <Panel header="Detalle">
            <BasicDatatable
            :headers="headers"
            :rows="items"
            :selectionMode="'single'"
            :headerVisible="false"
            />
        </Panel>
    </div>
</div>
</template>

<script>
import FormInputText from '../../../components/general/FormInputText.vue';
import FormInputNumber from '../../../components/general/FormInputNumber.vue';
import FormCalendar from '../../../components/general/FormCalendar.vue';
import Session from "../../../mixins/sessionMixin";
import FormDropdown from '../../../components/general/FormDropdown.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import Loader from "../../../components/general/Loader.vue"
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue'
import Helper from '../../../components/general/HelperDialog.vue';
import { EntryExitWareHouse } from '../../../models/inventarios/EntryExitWareHouse';
import {Order} from '../../../models/comercial/Order';
import {
    HeaderGrid,
    validate,
    Rule,
    ErrorToast,
    fillObject,
    Toast
  } from "../../../utilities/General";
import { InventoryWarehouse } from '../../../models/inventarios/InventoryWarehouse';
import { InventorySubWarehouse } from '../../../models/inventarios/InventorySubWarehouse';
import { InventoryArticle } from '../../../models/inventarios/InventoryArticle';
import { EntryExitItem } from '../../../models/inventarios/EntryExitItem';

export default{
    mixins: [Session],
    props: {
    modal: null,
    },

    data(){
        return{
            loading: false,
            entity: new EntryExitWareHouse(),
            entities:[],
            order: new Order(),
            orders: [],
            item: new EntryExitItem(),
            articles:[],
            purchases:[],
            warehouses:[],
            subwarehouses:[],
            type:[
                {id:'1', text:'Entrada'},
                {id:'2', text:'Salida'}
            ],
            movement:[],
            id_request:{
              visible: false
            },
            requestHelper:{
                visible: false
            },
            purchaseHelper:{
                visible: false
            },
            warehouseHelper:{
              visible: false
            },
            subwarehouseHelper:{
              visible: false
            },
            itemHelper:{
              visible: false
            },
            Helper:{
                visible: false
            },
            headers:[
                new HeaderGrid('Artículo','name'),
                new HeaderGrid('Descripción','key_name'),
                new HeaderGrid('UM','measure_unit'),
                new HeaderGrid('Almacén','id_inventory_warehouse'),
                new HeaderGrid('Localización','id_inventory_subwarehouse'),
                new HeaderGrid('Cantidad','quantity'),
                new HeaderGrid('Costo','unit_price',{type:'currency'}),
            ],
            requestHeaders:[
                new HeaderGrid('Folio','id'),
                new HeaderGrid('Cliente','name'),
                new HeaderGrid('Dirección','address'),
                new HeaderGrid('Estatus','name_status',{type: 'component', component:'Tag'}),
            ],
            movementHeaders:[
                new HeaderGrid('Folio','id'),
                new HeaderGrid('Tipo Movimiento','type'),
                new HeaderGrid('Movimiento','movement'),
                new HeaderGrid('Fecha','date',{type: 'date'}),
                new HeaderGrid('Estatus','',{type: 'component', component:'Tag'}),
            ],
            purchaseHeaders:[
                new HeaderGrid('Proveedor',''),
                new HeaderGrid('Fecha',''),
                new HeaderGrid('Total',''),
                new HeaderGrid('Estatus','')
            ],
            warehouseHeaders:[
            new HeaderGrid('Folio','id'),  
            new HeaderGrid('Nombre','name')
            ],
            subwarehouseHeaders:[
              new HeaderGrid('Folio','id'),  
              new HeaderGrid('Nombre','name')
            ],
            itemHeaders:[
              new HeaderGrid('Artículo','id'),
              new HeaderGrid('Descripción','key_name'),
              new HeaderGrid('Costo','unit_price',{type:'currency'}),
              new HeaderGrid('Unidad de Medida','strdescriptionmeasure'),
            ],
            validate: {
                valid: false,
                validations: {
                    type: null,
                    date: null,
                    comments: null,
                    movement: null
                },
            },
            rules: [
                new Rule({ name: "type_movement" }),
                new Rule({ name: "date"  }),
                new Rule({ name: "comments" }),
                new Rule({ name: "movement" }),
            ],
            rulesItem: [
                new Rule({ name: "id_item" }),
                new Rule({ name: "quantity" }),
                new Rule({ name: "unit_price" }),
                new Rule({ name: "id_inventory_warehouse" }),
                new Rule({ name: "id_inventory_subwarehouse" }),
            ],
            validateItem: {
                valid: false,
                validations: {
                    id_item: null,
                    id_inventory_warehouse: null,
                    id_inventory_subwarehouse: null,
                    quantity: null,
                    unit_price: null

                },
            },
        }
    },

    components:{FormInputText,FormInputNumber,FormCalendar,FormDropdown,BasicDatatable,Loader,BasicFormToolbar,Helper},

    created() {
        this.entity = new EntryExitWareHouse(this.session);
        this.item = new EntryExitItem(this.session);
      },
      async mounted() {
        await this.refresh();
      },
     computed: {
       items() {
           return this.entity.items.filter(x => (x.is_deleted ?? false) == false);
        }
      },
      methods: {
        openNew() {
          this.entity = new EntryExitWareHouse(this.session);
          this.item = new EntryExitItem(this.session);
        },            
        async save() {
          try {
            //* Validacion de form
            this.loading = true;
            this.validate = validate(this.entity, this.rules);
            if (!this.validate.valid) {
            throw "Favor de validar los campos";
            }              
            //* Si el id es != 0 entonces actualizamos
            if (this.entity.id && this.entity.id > 0) {
              //* Actualizamos
              let entity = await this.entity.update();
              this.$toast.add({
                severity: "success",
                summary: "Actualizar",
                detail: "Informacion actualizada con exito",
                life: 3000,
              });
              this.$emit('update', entity);
              //* Modificamos el listado pah
              let index = this.entities.findIndex((x) => x.id == this.entity.id);
              this.entities[index] = entity;
            }
            else {
                //* Creamos uno nuevo
                let entity = await this.entity.save();
                //* Agregamos un dato extra
                this.$emit('save', entity);
                this.entities.push(entity);
                this.$toast.add(
                    new Toast({
                    summary: "Creacion",
                    detail: "Informacion guardada con exito",
                    })
                );
            }
            this.entity = new EntryExitWareHouse(this.session);
            this.entities = null;
            if (this.open == true){
              this.entities = await this.entity.all();
            }
          } catch (error) {
            this.$toast.add(new ErrorToast(error));
          } finally {
            this.loading = false;
          }
        },

        saveItem() {
            try {
                this.validateItem = validate(this.item, this.rulesItem);
                this.validate = validate(this.entity, this.rules);
                if (!this.validateItem.valid && !this.validate.valid) {
                    throw "Favor de validar los campos";
                }
                this.item.fillSession(this.session);
                this.item.type_movement = this.entity.type_movement;
                let item = { ...this.item};
                if (item.id_number) {
                    let index = this.entity.items.findIndex(x => x.id_number == this.item.id_number);
                    this.entity.items[index] = item;
                }else {
                    item.id_number = item.id_number ?? this.entity.items.length + 1;
                    this.entity.items.push(item);
                    this.$toast.add(
                    new Toast({
                    summary: "Creacion",
                    detail: "Informacion guardada con exito",
                    })
                  );
                  console.log(this.entity.items);
                }
                this.item = new EntryExitItem(this.session);
                //this.$refs.quantity.focus();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },

        async refresh() {
          this.loading = true;
          try {
            if (!this.modal) 
              this.entities = await this.entity.all();
              console.log(this.entities);
              this.orders = await this.order.all();
              console.log(this.orders);
              this.warehouses = await new InventoryWarehouse(this.session).all();
              this.articles = await new InventoryArticle(this.session).getCustom();
              if (this.id) {
              this.entity.id = this.id;
              let entity = await this.entity.retrieve();
              this.entity = fillObject(this.entity, entity);
            }
          } catch (error) {
            this.$toast.add(new ErrorToast(error));
          } finally {
            this.loading = false;
          }
        },
      async selectRequest(payload){
        this.entity.id_request = payload.id;
        this.list();
      },

      async selectWarehouse(payload){
        this.item.id_entry_exit_warehouse = payload.id;
        this.subwarehouses = await new InventorySubWarehouse(this.session).Subwarehouse(this.item.id_entry_exit_warehouse);
      },

      async selectSubwarehouse(payload){
        this.item.id_entry_exit_subwarehouse = payload.id;
      },

      async selectItem(payload){
        this.item.measure_unit = payload.strdescriptionmeasure;
        this.item = fillObject(this.item, payload);
        this.item.id_item = payload.id;
        this.item.id = null;
        console.log(payload)
      },

      async selectMovement(payload){
        this.entity = fillObject(this.entity, payload);
        this.entity.date = new Date(payload.date);
        console.log(this.entity);

      },

      typeChanged(){
        if(this.entity.type_movement == 1){
          this.movement = [
          {text:'Entrada de almacén'},
          {text:'Entrada por orden de compra'},
          ]
        }else{
          this.movement = [
            {text:'Salida de almacén'},
            {text:'Salida por pedido'}
          ]
      }
    }   
  } 
}
</script>

<style>
</style>